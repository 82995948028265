import React from "react";
import { BrowserRouter, Routes, Route, useParams, useSearchParams } from 'react-router-dom';
import { CurDate } from '../src/client/k_functions.js'
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

/* telas de clientes */
import ListarClientes from "./client/Clientes/ClientesListar";
import ClientesDetalhe from "./client/Clientes/ClientesDetalhe";
import InserirClientes from "./client/Clientes/ClientesInserir";

/* telas de estoque */
import SituacaoEstoque from "./client/Estoque/Situacao";
import ListaEstoqueRet from "./client/Estoque/ARetirarListar";
import LancaEstoqueRet from "./client/Estoque/ARetirarLancar";
import ListarMovEstoque from "./client/Estoque/MovEstListar";
import ListarMovEstResult from "./client/Estoque/MovEstListarResult";
import MovimentarEstoque from "./client/Estoque/Movimentar";

/* telas de financas */
// tela com campos para filtros de parcelas, que podem ser usadas para quitação ou para listagem 
import FinancasFiltrar from "./client/Financas/FinancasFiltrar";
// tela que lista as parcelas filtradas, com checkbox para quitação, e processa a quitação em si
import QuitarListarEProcessar from "./client/Financas/QuitarListarEProcessar.js";
// lista de parcelas, sejam aquelas selecionadas no filtro, sejam as recentemente quitadas exibidas após quitacao
import ListarParcelas from "./client/Financas/FinancListar";
// tela com parametros para relatório financeiro
import RelatFinanc from "./client/Financas/FinancRelat";
// resultado do relatorio financeiro
import RelatFinancResult from "./client/Financas/FinancRelatResult";
// detalhes de uma parcela
import ParcelasDetalhe from "./client/Financas/FinancasDetalhe.js";

/* telas de formas de pagamento */
import ListarFormasPgto from "./client/FormasPag/FormasPagListar"; 

/* telas de login, home (menu principal) e Not Found */
import Login from "./client/Login";
import Home from "./client/Home";
import NotFound from "./client/NotFound";

/* telas de precos */
import ListarPrecos from "./client/Precos/PrecosListar";
import InserirPrecos from "./client/Precos/PrecosInserir";
import PrecosDetalhe from "./client/Precos/PrecosDetalhe";

/* telas de produtos */
import ListarProdutos from "./client/Produtos/ProdutosListar";
import ProdutosDetalhe from "./client/Produtos/ProdutosDetalhe";

/* telas de unidades de medida */
import ListarUnidades from "./client/Unidades/UnidadesListar"; 

/* telas de usuários */
import ListarUsuarios from "./client/Usuarios/UsuariosListar";

/* telas de vendas */
import Vender from "./client/Vendas/Vender";
import VenderCupom from "./client/Vendas/VenderCupom";
import ListarVendas from "./client/Vendas/VendasListar";
import ListarVendasResult from "./client/Vendas/VendasListarResult";
import ListarVendasA from "./client/Vendas/VendasAbertoListar";
import VendasDetalhe from "./client/Vendas/VendasDetalhes";
import RelatVendas from "./client/Vendas/VendasRelat";
import RelatVendasResult from "./client/Vendas/VendasRelatResult";
import VenderAlterar from "./client/Vendas/VenderAlterar";
import ChecarVendas from "./client/Vendas/VendasChecar";
import ChecarVendasResult from "./client/Vendas/VendasChecarResult";

// obter o id do cliente e a partir daí chamar a ClientesDetalhe
function ClientesDetalheU() {
  const { id } = useParams();  
  return (
    <ClientesDetalhe idCliente={ id } />
  )
}

// obter o id do produto e a partir daí chamar a ProdutosDetalhe
function ProdutosDetalheU() {
  const { id } = useParams();  
  return (
    <ProdutosDetalhe idProduto={ id } />
  )
}

// obter o id do preco e a partir daí chamar a PrecosDetalhe
function PrecosDetalheU() {
  const { id } = useParams();  
  return (
    <PrecosDetalhe idPreco={ id } />
  )
}

// id da venda e a partir daí chamar a VendasDetalhe
function VendasDetalheU() {
  const { id } = useParams();  
  return (
    <VendasDetalhe idVenda={ id } />
  )
}

// obter o id da venda e a partir daí chamar a VenderCupom2
function VenderCupomU() {
  const { id } = useParams();  
  return (
    <VenderCupom idVenda={ id } />
  )
}

// obter o id do cliente clicado na consulta de clientes e a partir daí chamar a Venda para este cliente
// chamei a variavel de nCLiente, mas o certo seria idCliente, porem no Vender.js já tinha várias ocorrencias de nCliente, então mantive nCliente
function VenderClienteU() {
  const { id, nome } = useParams();  
  return (
    <Vender nCliente={ id } sNome={ nome } />
  )
}

// obter o id da venda
// ela é chamada quando o usuário clica em uma venda em andamento, visando dar continuidade à mesma
function VenderV() {
  const { id } = useParams();  
  return (
    <Vender idVenda={ id } />
  )
}

// obter o id da linha da venda e a partir daí chamar a VenderAlterar
function VenderAlterarU() {
  const { id } = useParams();  
  return (
    <VenderAlterar idVendaLin={ id } />
  )
}

// obter o id da venda clicado na consulta de estoque a entregar e a partir daí chamar a tela de entregas para esta venda
function LancaEstoqueRetU() {
  const { id } = useParams();  
  return (
    <LancaEstoqueRet idVenda={ id } />
  )
}

// obter o id da parcela e a partir daí chamar a ParcelasDetalhe
// layout será completo (Full) quando estiver na consulta normal de parcelas, e 
// não será full quando chamada a partir da tela de quitação, quando será mostrada em formato modal
function ParcelasDetalheU() {
  const params = useParams();
  return (
    <ParcelasDetalhe idParcela={ params.id } retPage={ params.ret } bLayoutFull={true} idVenda={ 0 }/>
  )
}

// obter o id da parcela target do filtro, podendo ser a de quitação ou a de listagem de parcelas
function FinancasFiltrarU() {
  const params = useParams();
  return (
    <FinancasFiltrar target={ params.target } />
  )
}

// obter filtros do relatório e a partir daí chamar a RelatVendasResult
function RelatVendasResultU() {
  const [ searchParams ] = useSearchParams();
  return (
    <RelatVendasResult dIni={ searchParams.get("DatIni") } 
                       dFim={ searchParams.get("DatFim") }
                       cPro={ searchParams.get("CodPro") }
                       cCli={ searchParams.get("CodCli") }
                       cTCl={ searchParams.get("cTotCli") }
                       cTPr={ searchParams.get("cTotPro") }
                       cTCP={ searchParams.get("cTotCliPro") }
                       cTDi={ searchParams.get("cTotDia") }
                       cTDP={ searchParams.get("cTotDiaPro") }
                       cTAM={ searchParams.get("cTotA_M") } // total ano / mês
                       cPMA={ searchParams.get("cTotPMA") } // pendencias de meses anteriores
    />
  )
}

// obter filtros da seleção de parcelas para listagem
// esta função é chamada direta do menu ou após a quitação
// quando chamada do menu, o usuário é que informa a data de inicio e fim de vencimento
// qdo chamada apos a quitação, estes valores são null, então são inicializados aqui
function ListarParcelasU() {
  const [ searchParams ] = useSearchParams();
  let dDatIni = CurDate("YMD");
  let dDatFim = CurDate("YMD");
  let dDatIniVd = CurDate("YMD");
  let dDatFimVd = CurDate("YMD");
  let cCodCli = 0;
  let sStsPar = "Quitado";
  if (searchParams.get("DatIni") !== null)
  {
    dDatIni = searchParams.get("DatIni");
    dDatFim = searchParams.get("DatFim");
    dDatIniVd = searchParams.get("DatIniVd");
    dDatFimVd = searchParams.get("DatFimVd");
    cCodCli = searchParams.get("CodCli");
    sStsPar = "Todos";
  }
  return (
    <ListarParcelas dIni={ dDatIni } 
                    dFim={ dDatFim }
                    dIVd={ dDatIniVd }
                    dFVd={ dDatFimVd }
                    cCli={ cCodCli }
                    sSts={ sStsPar }
    />
  )
}

// obter filtros da seleção de parcelas para quitação 
function QuitarSelecionarResultU() {
  const [ searchParams ] = useSearchParams();
//  console.log(searchParams);
  return (
    <QuitarListarEProcessar dIni={ searchParams.get("DatIni") } 
                            dFim={ searchParams.get("DatFim") }
                            dIVd={ searchParams.get("DatIniVd") }
                            dFVd={ searchParams.get("DatFimVd") }
                            cCli={ searchParams.get("CodCli") }
    />
  )
}

// obter filtros do relatório e a partir daí chamar a ListarVendasResult
function ListarVendasResultU() {
  const [ searchParams ] = useSearchParams();
//  console.log(searchParams);
  return (
    <ListarVendasResult dIni={ searchParams.get("DatIni") } 
                        dFim={ searchParams.get("DatFim") }
                        tDat={ searchParams.get("TipDat") }
                        cCli={ searchParams.get("CodCli") }
                        vIni={ searchParams.get("VenIni") } 
                        vFim={ searchParams.get("VenFim") }
    />
  )
}

// obter filtros do relatório e a partir daí chamar a ChecarVendasResult
function ChecarVendasResultU() {
  const [ searchParams ] = useSearchParams();
//  console.log(searchParams);
  return (
    <ChecarVendasResult vIni={ searchParams.get("VenIni") } 
                        vFim={ searchParams.get("VenFim") }
    />
  )
}

// obter as filtros do relatório e a partir daí chamar a RelatFinancResult
function RelatFinancResultU() {
  const [ searchParams ] = useSearchParams();
//  console.log(searchParams);
  return (
    <RelatFinancResult dIni={ searchParams.get("DatIni") } 
                       dFim={ searchParams.get("DatFim") }
                       cFor={ searchParams.get("CodFor") }
                       cCli={ searchParams.get("CodCli") }
                       cTCF={ searchParams.get("cTotCxF") }
                       cTCl={ searchParams.get("cTotCli") }
                       cTFo={ searchParams.get("cTotFor") }
                       cTDi={ searchParams.get("cTotDia") }
                       cSit={ searchParams.get("cSitu") } // situação: emitido, pago, pendente
    />
  )
}

// obter filtros do relatório e a partir daí chamar a ListarMovEstResult
function ListarMovEstResultU() {
  const [ searchParams ] = useSearchParams();
//  console.log(searchParams);
  return (
    <ListarMovEstResult dIni={ searchParams.get("DatIni") } 
                        dFim={ searchParams.get("DatFim") }
    />
  )
}

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/"           element={<Login />} />
          <Route path="/index.html" element={<Login />} />
          <Route path="/home"       element={<Home />} />

          <Route path="/vender"     element={<Vender nCliente={0}/>} />
          <Route path="/vender/:id" element={<VenderV />} />
          <Route path="/vender/:id/:nome" element={<VenderClienteU />} />
          <Route path="/vencup/:id" element={<VenderCupomU />} />
          <Route path="/venlis"     element={<ListarVendas />} />
          <Route path="/venlisres"  element={<ListarVendasResultU />} />
          <Route path="/venlisA"    element={<ListarVendasA />} />
          <Route path="/vendet/:id" element={<VendasDetalheU />} />
          <Route path="/venrel"     element={<RelatVendas />} />
          <Route path="/venrelres"  element={<RelatVendasResultU />} />
          <Route path="/venalt/:id" element={<VenderAlterarU />} />
          <Route path="/venchk"     element={<ChecarVendas />} />
          <Route path="/venchkres"  element={<ChecarVendasResultU />} />

          <Route path="/estmov"     element={<MovimentarEstoque />} />
          <Route path="/estsit"     element={<SituacaoEstoque />} />
          <Route path="/estret"     element={<ListaEstoqueRet />} />
          <Route path="/estent/:id" element={<LancaEstoqueRetU />} />
          <Route path="/estlis"     element={<ListarMovEstoque />} />
          <Route path="/estlisres"  element={<ListarMovEstResultU />} />

          <Route path="/clilis"     element={<ListarClientes />} />
          <Route path="/clidet/:id" element={<ClientesDetalheU />} />
          <Route path="/cliadd"     element={<InserirClientes />} />

          <Route path="/prolis"     element={<ListarProdutos />} />
          <Route path="/prodet/:id" element={<ProdutosDetalheU />} />

          <Route path="/prelis"     element={<ListarPrecos />} />
          <Route path="/predet/:id" element={<PrecosDetalheU />} />
          <Route path="/preadd"     element={<InserirPrecos />} />

          <Route path="/forlis"     element={<ListarFormasPgto />} />
          <Route path="/usulis"     element={<ListarUsuarios />} />
          <Route path="/unilis"     element={<ListarUnidades />} />

          <Route path="/finsel/:target"      element={<FinancasFiltrarU />} />
          <Route path="/quitarlis"           element={<QuitarSelecionarResultU />} />
          <Route path="/finlis"              element={<ListarParcelasU />} />
          <Route path="/finrel"              element={<RelatFinanc />} />
          <Route path="/finrelres"           element={<RelatFinancResultU />} />
          <Route path="/pardet/:id/ret/:ret" element={<ParcelasDetalheU />} />

          <Route path="*"                    element={<NotFound />} />
        </Routes>   
      </BrowserRouter>
    </div>
  );
}

export default App;