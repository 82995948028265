import React, { Component } from "react";
import CabPage from "../CabPage";
import axios from "axios";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FloatingLabel from "react-bootstrap/esm/FloatingLabel";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/esm/Button";
import { FormataValor, trataAxios, SelectTipoCli } from "../k_functions";

class ClientesDetalhe extends Component {
  state = {
    oCliente: []
  };

  /* requisição com axios para ler detalhe do cliente */
  async componentDidMount() {
//    alert(100);
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Clientes/ClientesDetalhe.php",
        func_trata: "ClientesDetalhe",
        id_cliente: this.props.idCliente
      }
    })
    .then((response) => {
      this.setState({ oCliente: response.data });
//      console.log(this.state.oCliente);
    })
    .catch(function (error) { console.log(error); alert(error) })
  }

  handleChangeCPFCNPJ = () => e => {  
    let oCliente = this.state.oCliente;
    oCliente.cnpj_cpf = e.target.value;
    this.setState({ oCliente });
  };
    
  handleChangeNomeRazao = () => e => {  
    let oCliente = this.state.oCliente;
    oCliente.nome_razao = e.target.value;
    this.setState({ oCliente });
  };

  handleChangeFantasia = () => e => {  
    let oCliente = this.state.oCliente;
    oCliente.fantasia = e.target.value;
    this.setState({ oCliente });
  };

  handleChangeTipoCli = () => e => {  
    let oCliente = this.state.oCliente;
    oCliente.tipo_cliente = e.target.value;
    this.setState({ oCliente });
  };

  handleChangeAtivo = () => e => {
    console.log(e.target);
    let oCliente = this.state.oCliente;
    oCliente.cliente_ativo = (e.target.value === "S" ? "N" : "S");
    this.setState({ oCliente });
  };

  handleChangePercDesc = () => e => {  
    let oCliente = this.state.oCliente;
    oCliente.perc_desc_redid = e.target.value;
    this.setState({ oCliente });
  };

  // chamado ao clicar no botão de alterar
  handleSubmit = (event) => {
    let isConfirmed = true;
    let oDadosCli = {};

    if (! window.confirm("Confirma as alterações ?"))
    {
      event.preventDefault();
      event.stopPropagation();
      isConfirmed = false;
    } 
   
    // se os dados são válidos e confirmou a alteração
    if (isConfirmed)
    {
      // chama o programa php para atualizar o cliente
      oDadosCli = {
        // id do preço
        id_cliente: this.state.oCliente.id_cliente,
        cnpj_cpf: this.state.oCliente.cnpj_cpf,
        nome_razao: this.state.oCliente.nome_razao,
        fantasia: this.state.oCliente.fantasia,
        tipo_cliente: this.state.oCliente.tipo_cliente,
        cliente_ativo: this.state.oCliente.cliente_ativo,
        perc_desc_redid: this.state.oCliente.perc_desc_redid,
        // programa e funcao que vao salvar os dados
        prog_trata: "./Clientes/ClientesAlterar.php",
        func_trata: "AlteraCliente"
      };

//      console.log(oDadosCli);
      trataAxios(oDadosCli);
    }
  };

  render() { 
    return (
      <>
        <CabPage titulo="Cliente" subtitulo="Detalhar / Alterar" classSubTit="sFonte6Branco" paginavoltar="/clilis" />
        <div className="App-body">
          <form onSubmit={this.handleSubmit} action="/clilis">
            <Row>
              <Col>
                <FloatingLabel controlId="floatingItens" label="ID">
                  <Form.Control type="number" value={this.state.oCliente.id_cliente} disabled/>
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="floatingTotal" label="CNPJ/CPF">
                    <Form.Control type="text" value={this.state.oCliente.cnpj_cpf} 
                    onChange={this.handleChangeCPFCNPJ()}/>
                </FloatingLabel>
              </Col>
            </Row>
            <br/>
            <FloatingLabel controlId="floatingTotal" label="Nome / Razao Social">
              <Form.Control type="text" value={this.state.oCliente.nome_razao} 
              onChange={this.handleChangeNomeRazao()}/>
            </FloatingLabel>
            <br/>
            <FloatingLabel controlId="floatingTotal" label="Fantasia">
              <Form.Control type="text" value={this.state.oCliente.fantasia}
              onChange={this.handleChangeFantasia()}/>
            </FloatingLabel>
            <br/>
            <Row>
              <Col>
                <FloatingLabel controlId="floatingItens" label="Tipo">
                  <Form.Select onChange={this.handleChangeTipoCli()} value={this.state.oCliente.tipo_cliente}>
                    <SelectTipoCli />
                  </Form.Select>                    
                </FloatingLabel>
              </Col>
              <Col>
                  <Form.Check inline type="switch" label="Cliente ativo ?" 
                    value={this.state.oCliente.cliente_ativo} checked={this.state.oCliente.cliente_ativo === "S"}
                    onChange={this.handleChangeAtivo()}
                  />
              </Col>
            </Row>
            <br/>
            <Row>
              <Col>
                <FloatingLabel controlId="floatingTotal" label="ID RedID">
                  <Form.Control type="text" value={this.state.oCliente.id_redid} disabled/>
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="floatingPercDesc" label="% Desconto RedID">
                  <Form.Control type="number" value={this.state.oCliente.perc_desc_redid > 0 ? this.state.oCliente.perc_desc_redid : 0} 
                  onChange={this.handleChangePercDesc()}/>
              </FloatingLabel>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col>
                <FloatingLabel controlId="floatingEmAberto" label="Em atraso">
                  <Form.Control type="text" value={FormataValor(this.state.oCliente.totAtraso)} disabled/>
                </FloatingLabel>
              </Col>
              <Col/>
            </Row>
            <br/>
            <center>
              <Button as="button" type="submit" id="bt_upd" 
                variant="success" size="lg">Atualizar cliente
              </Button>
            </center>
          </form>
        </div>
      </>
    );
  }
}

export default ClientesDetalhe;