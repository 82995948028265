import React, { Component, useEffect, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import axios from "axios";
import { FormataValor } from "../k_functions.js";

// https://www.digitalocean.com/community/tutorials/how-to-handle-async-data-loading-lazy-loading-and-code-splitting-with-react
// https://legacy.reactjs.org/docs/hooks-effect.html

function GetDebCli(parameters) {
  let [nTotAtrasado, setnTotAtrasado] = useState("");
  if (parameters.nCli > 0)
  {
    // requisição com axios para ler debitos do cliente
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Clientes/ClientesDetalhe.php",
        func_trata: "ClientesDetalhe",
        id_cliente: parameters.nCli
      }
    })
    .then((response) => {
      if (response.data.totAtraso > 0)
        setnTotAtrasado("Valor em atraso: R$ " + FormataValor(response.data.totAtraso));
      else
        setnTotAtrasado("Não há valores em atraso.");
    })
    .catch(function (error) { console.log(error); alert(error) })
    
    /*
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
      // Update the document title using the browser API
  //    document.title = `You clicked d ${nTotAtraso} times`;

    });
*/
    return (
      <div>
        <div className='sFonte7Verde'>{parameters.sCli}</div>
        <div className='sAtraso'>{nTotAtrasado}</div>
      </div>
    );
  }
}

class VenderCli extends Component {
  state = {
    aClientes: [],  // contém a lista de clientes filtrados
    aClientesAll: []  // contém todos os clientes
  };

  // requisição com axios para ler lista de clientes
  async componentDidMount() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Clientes/ClientesListar.php",
        func_trata: "ClientesListar"
      }
    })
    .then((response) => {
      this.setState({ aClientes: response.data });
      this.setState({ aClientesAll: response.data });
    })
    .catch(function (error) {console.log(error); alert(error)})
  }

  // filtra clientes conforme usuário vai digitando no campo filtrar
  handleLocalizar = (e) => {
    const aClientes = this.state.aClientesAll.filter(el => 
      ((el.nome_razao.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) ||
       (el.fantasia.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) ||
       (el.id_cliente.toString().indexOf(e.target.value) > -1)));
    this.setState({aClientes: aClientes});
  }

  render() {
    return (
      <>
        <div className="App-body">
          <GetDebCli sCli={this.props.aCarrinho.sRazClie} nCli={this.props.aCarrinho.nCliente} />
          <form>
              <input className="form-control mr-sm-2" type="search" placeholder="Localizar" aria-label="Search" onChange={this.handleLocalizar}/>
          </form>
          <ListGroup className="listgroup">
            {this.state.aClientes.map((oCliente, index) => (
              <ListGroup.Item key={index} action 
                onClick={(e) => {this.props.rotSetCli(oCliente)}}>
                {oCliente.id_cliente + ' - ' + oCliente.fantasia}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      </>
    );
  }
}

export default VenderCli;